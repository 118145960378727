import React, { FC } from 'react'

type RealmBusinessProps = {
  className?: string
}

const RealmBusiness: FC<RealmBusinessProps> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 282 61" fill="none">
      <g clipPath="url(#clip0_1394_35059)">
        <path
          d="M272.864 26.48L272.649 26.6973L272.644 28.3183C272.644 32.864 272.191 33.3236 267.682 33.3236H266.071L265.857 33.5409V35.1119L266.071 35.3292L267.679 35.3342C272.193 35.3342 272.649 35.7913 272.649 40.332V41.9555L272.864 42.1728H274.424L274.639 41.9555L274.644 40.3345C274.644 35.7888 275.097 35.3292 279.606 35.3292H281.217L281.431 35.1119V33.5409L281.217 33.3236L279.609 33.3186C275.095 33.3186 274.639 32.8615 274.639 28.3208V26.6973L274.424 26.48H272.864Z"
          fill="#146D4D"
        />
        <path
          d="M258.512 36.8877L258.188 37.2124L258.18 39.6501C258.18 46.4912 257.188 47.4953 250.401 47.4953H247.975L247.651 47.8199V49.5583L247.975 49.883L250.396 49.8905C257.188 49.8905 258.188 50.8896 258.188 57.7257V60.1684L258.512 60.4931H260.239L260.563 60.1684L260.568 57.7307C260.568 50.8896 261.56 49.8855 268.35 49.8855H270.776L271.1 49.5608V47.8224L270.776 47.4978L268.355 47.4903C261.563 47.4903 260.563 46.4912 260.563 39.6551V37.2124L260.239 36.8877H258.512Z"
          fill="#146D4D"
        />
        <path
          d="M62.2007 13.1177V40.2323L62.4824 40.5145H65.4262L65.7078 40.2323V13.1177L65.4262 12.8354H62.4824L62.2007 13.1177Z"
          fill="#146D4D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.0226 35.0821L39.9977 35.157C39.3994 36.9279 38.3924 38.4015 36.989 39.4355C35.5857 40.4696 33.7935 41.0515 31.6348 41.0515C28.8156 41.0515 26.5199 39.99 24.9271 38.1517C23.3368 36.316 22.4644 33.7184 22.4644 30.6613C22.4644 27.6041 23.3393 25.0066 24.9271 23.1708C26.5174 21.3325 28.8156 20.271 31.6348 20.271C34.4515 20.271 36.655 21.2726 38.1531 23.0784C39.6487 24.8792 40.4239 27.4568 40.4239 30.5863V30.8411L39.7634 31.508H26.9461L26.0912 32.4021C26.2856 33.8682 26.8091 35.2544 27.6989 36.276C28.6013 37.31 29.89 37.9819 31.6348 37.9819C34.0427 37.9819 35.5932 36.7555 36.5204 35.0347L36.5304 35.0147L36.8569 34.685H39.7384L40.0201 35.0821H40.0226ZM27.1331 28.6656H35.9072L36.7547 27.8114C36.488 26.4477 35.987 25.3437 35.1844 24.5745C34.3668 23.7927 33.2202 23.3431 31.6348 23.3431C30.1118 23.3431 28.9403 23.8302 28.0678 24.6344C27.2104 25.4237 26.6321 26.5326 26.2906 27.8189L27.1331 28.6681V28.6656Z"
          fill="#146D4D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.0436 27.0071C54.924 25.8932 54.5376 24.979 53.8796 24.3396C53.2115 23.6877 52.2444 23.3056 50.9432 23.3056C49.7692 23.3056 48.7821 23.5778 48.0194 24.0849C47.2566 24.5919 46.7082 25.3362 46.4141 26.3003L46.4016 26.3378L46.0626 26.6799H43.2933L43.0166 26.2903L43.0291 26.2229C43.3581 24.6244 44.1134 23.1333 45.4071 22.0443C46.7007 20.9528 48.5154 20.2734 50.9407 20.2734C53.5805 20.2734 55.4699 21.1102 56.6988 22.4714C57.9252 23.8301 58.4735 25.6934 58.4735 27.7115V40.2347L58.1944 40.517H55.4425L55.1608 40.2347V38.5238H54.2435C52.9922 39.95 50.9532 40.9765 48.468 40.9765C46.5811 40.9765 44.9933 40.522 43.8741 39.6203C42.7499 38.7161 42.1143 37.3749 42.1143 35.6465C42.1143 33.9381 42.6726 32.5619 43.9314 31.4605C45.1827 30.3665 47.1145 29.5547 49.844 28.9403L54.2086 27.9537L55.0436 27.0071ZM46.4839 37.4698C47.0522 37.8794 47.8997 38.0967 49.004 38.0967C50.9258 38.0967 52.4313 37.4224 53.4583 36.2784C54.4753 35.147 55.0362 33.546 55.0486 31.6503L54.246 30.9085L50.8261 31.6952C48.5303 32.2297 47.2317 32.8267 46.5063 33.4811C45.7934 34.123 45.6214 34.8298 45.6214 35.6465C45.6214 36.4658 45.9255 37.0677 46.4839 37.4698Z"
          fill="#146D4D"
        />
        <path
          d="M84.2742 22.8786C85.5954 21.1527 87.4873 20.271 89.601 20.271C91.7248 20.271 93.3325 20.9054 94.4069 22.1692C95.4762 23.4305 95.9947 25.2938 95.9947 27.709V40.2323L95.7155 40.5145H92.7717L92.49 40.2323V28.0937C92.49 26.1904 92.1111 25.034 91.4406 24.3497C90.7701 23.6653 89.778 23.418 88.4594 23.418C87.2629 23.418 86.2858 23.8426 85.6003 24.7643C84.9099 25.6934 84.5036 27.1495 84.5036 29.2426V40.2323L84.2244 40.5145H81.2806L80.9989 40.2323V28.0937C80.9989 26.1904 80.62 25.034 79.9495 24.3497C79.279 23.6653 78.2869 23.418 76.9683 23.418C75.7718 23.418 74.7947 23.8426 74.1117 24.7643C73.4212 25.6934 73.0149 27.1495 73.0149 29.2426V40.2323L72.7357 40.5145H69.7919L69.5103 40.2323V21.0902L69.7894 20.808H72.7333L73.0149 21.0902V22.1492H73.85C74.8022 20.9529 76.3875 20.271 78.1099 20.271C79.421 20.271 80.4754 20.5133 81.3304 20.9753C82.1605 21.4224 82.7936 22.0693 83.2946 22.8786H84.2717H84.2742Z"
          fill="#146D4D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.1424 40.4046L0.818359 40.0799V13.2051L1.1424 12.8779H12.0403C14.7672 12.8779 17.058 13.5698 18.6683 14.951C20.286 16.3372 21.1908 18.3978 21.1908 21.0702C21.1908 23.1433 20.6325 24.7968 19.6304 26.0706C18.6533 27.3144 17.2674 28.1786 15.6098 28.7231L15.3082 29.6372L22.5244 39.9101L22.1704 40.4071H18.6284L18.2719 40.1524L10.7142 29.3625L10.4749 29.2276H5.54942L4.74429 30.0393V40.0824L4.42024 40.4096H1.1424V40.4046ZM5.54942 25.7634H11.6639C13.5409 25.7634 14.9168 25.3887 15.8216 24.6319C16.7165 23.8851 17.1926 22.7287 17.1926 21.0678C17.1926 19.4043 16.7439 18.2454 15.9064 17.4986C15.0639 16.7468 13.7826 16.3722 12.0428 16.3722H5.55191L4.74678 17.1839V24.9491L5.55191 25.7609L5.54942 25.7634Z"
          fill="#146D4D"
        />
        <path
          d="M109.496 13.2598H117.163C120.765 13.2598 123.37 13.7993 124.98 14.8782C126.59 15.9572 127.393 17.6631 127.393 19.9935C127.393 21.6069 126.944 22.9357 126.047 23.9847C125.15 25.0337 123.841 25.7131 122.121 26.0228V26.2101C126.244 26.917 128.303 29.0874 128.303 32.7215C128.303 35.1517 127.483 37.05 125.842 38.4137C124.202 39.7774 121.909 40.4593 118.963 40.4593H109.493V13.2598H109.496ZM112.651 24.9063H117.851C120.079 24.9063 121.682 24.5567 122.659 23.8548C123.636 23.153 124.125 21.9716 124.125 20.3107C124.125 18.7846 123.579 17.6856 122.49 17.0088C121.401 16.3319 119.668 15.9947 117.29 15.9947H112.649V24.9063H112.651ZM112.651 27.5863V37.7643H118.315C120.506 37.7643 122.156 37.3397 123.263 36.4905C124.369 35.6413 124.925 34.31 124.925 32.4992C124.925 30.8133 124.359 29.572 123.225 28.7777C122.094 27.9835 120.369 27.5863 118.053 27.5863H112.649H112.651Z"
          fill="#121316"
          fillOpacity="0.8"
        />
        <path
          d="M133.904 20.0708V33.2984C133.904 34.9618 134.281 36.2007 135.036 37.0199C135.791 37.8391 136.973 38.2488 138.583 38.2488C140.712 38.2488 142.27 37.6668 143.252 36.5004C144.237 35.334 144.728 33.4308 144.728 30.7883V20.0708H147.809V40.4617H145.266L144.82 37.7267H144.653C144.022 38.7308 143.145 39.5001 142.026 40.0346C140.904 40.5666 139.628 40.8338 138.192 40.8338C135.717 40.8338 133.862 40.2444 132.631 39.0655C131.399 37.8866 130.784 36.0034 130.784 33.4108V20.0708H133.902H133.904Z"
          fill="#121316"
          fillOpacity="0.8"
        />
        <path
          d="M165.573 34.897C165.573 36.7953 164.867 38.2589 163.456 39.2879C162.045 40.317 160.064 40.8315 157.514 40.8315C154.814 40.8315 152.71 40.4044 151.2 39.5477V36.6829C152.177 37.1799 153.226 37.5695 154.348 37.8543C155.467 38.139 156.549 38.2814 157.589 38.2814C159.196 38.2814 160.435 38.0241 161.303 37.5096C162.17 36.9951 162.601 36.2108 162.601 35.1568C162.601 34.3625 162.257 33.6832 161.572 33.1187C160.884 32.5542 159.545 31.8874 157.551 31.1181C155.657 30.4112 154.311 29.7943 153.513 29.2673C152.715 28.7403 152.12 28.1409 151.731 27.4715C151.342 26.8021 151.145 26.0029 151.145 25.0713C151.145 23.4103 151.821 22.0965 153.169 21.1374C154.518 20.1758 156.37 19.6963 158.72 19.6963C160.911 19.6963 163.052 20.1434 165.144 21.035L164.049 23.5477C162.008 22.7035 160.156 22.2814 158.498 22.2814C157.038 22.2814 155.936 22.5112 155.193 22.9707C154.45 23.4303 154.079 24.0622 154.079 24.8689C154.079 25.4159 154.218 25.8805 154.498 26.2651C154.777 26.6498 155.225 27.0144 155.844 27.3616C156.462 27.7088 157.651 28.2108 159.408 28.8677C161.821 29.7494 163.454 30.636 164.301 31.5277C165.149 32.4219 165.573 33.5433 165.573 34.8945V34.897Z"
          fill="#121316"
          fillOpacity="0.8"
        />
        <path
          d="M168.424 14.5436C168.424 13.8368 168.599 13.3198 168.945 12.9901C169.292 12.6604 169.725 12.498 170.244 12.498C170.762 12.498 171.166 12.6654 171.525 13.0001C171.884 13.3348 172.064 13.8493 172.064 14.5436C172.064 15.238 171.884 15.755 171.525 16.0972C171.166 16.4394 170.74 16.6092 170.244 16.6092C169.723 16.6092 169.292 16.4394 168.945 16.0972C168.599 15.755 168.424 15.238 168.424 14.5436ZM171.767 40.4593H168.684V20.0709H171.767V40.4618V40.4593Z"
          fill="#121316"
          fillOpacity="0.8"
        />
        <path
          d="M190.328 40.4618V27.2716C190.328 25.6107 189.949 24.3694 189.196 23.5501C188.441 22.7309 187.259 22.3213 185.649 22.3213C183.52 22.3213 181.96 22.8982 180.97 24.0521C179.981 25.2061 179.485 27.1093 179.485 29.7643V40.4618H176.401V20.0709H178.909L179.41 22.8608H179.56C180.19 21.8567 181.075 21.0774 182.214 20.5255C183.353 19.9735 184.622 19.6987 186.021 19.6987C188.471 19.6987 190.315 20.2907 191.554 21.4746C192.793 22.6585 193.411 24.5542 193.411 27.1592V40.4618H190.328Z"
          fill="#121316"
          fillOpacity="0.8"
        />
        <path
          d="M206.437 40.8339C203.428 40.8339 201.055 39.9172 199.315 38.0814C197.576 36.2457 196.706 33.6981 196.706 30.4361C196.706 27.1742 197.513 24.5391 199.128 22.6035C200.744 20.6678 202.912 19.7012 205.637 19.7012C208.187 19.7012 210.203 20.5404 211.689 22.2213C213.175 23.9022 213.917 26.1202 213.917 28.8726V30.8257H199.899C199.961 33.2185 200.564 35.0368 201.708 36.2756C202.852 37.517 204.465 38.1364 206.544 38.1364C208.735 38.1364 210.901 37.6768 213.042 36.7602V39.5126C211.953 39.9847 210.924 40.3218 209.952 40.5267C208.979 40.7315 207.808 40.8339 206.432 40.8339H206.437ZM205.602 22.2838C203.967 22.2838 202.666 22.8182 201.693 23.8848C200.721 24.9513 200.148 26.4274 199.976 28.3131H210.615C210.615 26.3649 210.181 24.8738 209.316 23.8373C208.448 22.8008 207.212 22.2838 205.602 22.2838Z"
          fill="#121316"
          fillOpacity="0.8"
        />
        <path
          d="M231.16 34.897C231.16 36.7953 230.454 38.2589 229.044 39.2879C227.633 40.317 225.651 40.8315 223.101 40.8315C220.402 40.8315 218.298 40.4044 216.787 39.5477V36.6829C217.764 37.1799 218.814 37.5695 219.935 37.8543C221.055 38.139 222.136 38.2814 223.176 38.2814C224.786 38.2814 226.023 38.0241 226.89 37.5096C227.757 36.9951 228.189 36.2108 228.189 35.1568C228.189 34.3625 227.845 33.6832 227.159 33.1187C226.471 32.5542 225.133 31.8874 223.139 31.1181C221.244 30.4112 219.898 29.7943 219.1 29.2673C218.303 28.7403 217.707 28.1409 217.318 27.4715C216.929 26.8021 216.732 26.0029 216.732 25.0713C216.732 23.4103 217.408 22.0965 218.756 21.1374C220.105 20.1758 221.957 19.6963 224.308 19.6963C226.499 19.6963 228.64 20.1434 230.731 21.035L229.634 23.5477C227.593 22.7035 225.741 22.2814 224.083 22.2814C222.623 22.2814 221.521 22.5112 220.778 22.9707C220.035 23.4303 219.664 24.0622 219.664 24.8689C219.664 25.4159 219.803 25.8805 220.083 26.2651C220.362 26.6498 220.81 27.0144 221.429 27.3616C222.047 27.7088 223.236 28.2108 224.993 28.8677C227.406 29.7494 229.039 30.636 229.886 31.5277C230.734 32.4219 231.157 33.5433 231.157 34.8945L231.16 34.897Z"
          fill="#121316"
          fillOpacity="0.8"
        />
        <path
          d="M248.16 34.897C248.16 36.7953 247.455 38.2589 246.044 39.2879C244.633 40.317 242.652 40.8315 240.102 40.8315C237.402 40.8315 235.298 40.4044 233.788 39.5477V36.6829C234.765 37.1799 235.814 37.5695 236.936 37.8543C238.055 38.139 239.137 38.2814 240.176 38.2814C241.787 38.2814 243.023 38.0241 243.89 37.5096C244.758 36.9951 245.189 36.2108 245.189 35.1568C245.189 34.3625 244.845 33.6832 244.16 33.1187C243.472 32.5542 242.133 31.8874 240.139 31.1181C238.245 30.4112 236.899 29.7943 236.101 29.2673C235.303 28.7403 234.708 28.1409 234.319 27.4715C233.93 26.8021 233.733 26.0029 233.733 25.0713C233.733 23.4103 234.408 22.0965 235.757 21.1374C237.105 20.1758 238.958 19.6963 241.308 19.6963C243.499 19.6963 245.64 20.1434 247.732 21.035L246.637 23.5477C244.596 22.7035 242.744 22.2814 241.086 22.2814C239.626 22.2814 238.524 22.5112 237.781 22.9707C237.038 23.4303 236.667 24.0622 236.667 24.8689C236.667 25.4159 236.806 25.8805 237.086 26.2651C237.365 26.6498 237.813 27.0144 238.432 27.3616C239.05 27.7088 240.239 28.2108 241.996 28.8677C244.409 29.7494 246.042 30.636 246.889 31.5277C247.737 32.4219 248.16 33.5433 248.16 34.8945V34.897Z"
          fill="#121316"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1394_35059">
          <rect width="280.8" height="60" fill="white" transform="translate(0.818359 0.498047)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RealmBusiness
