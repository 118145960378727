import React, { FC, useMemo, CSSProperties } from 'react'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import cx from 'classnames'

import { processPlaceholders, usePlaceholderReplacements } from './processPlaceholders'

import styles from './styles.module.scss'

export const hasTextPresent = (textData?: RichTextBlock[]): boolean => {
  return !!textData?.find((e) => (e.text?.length || 0) > 0)
}

interface Props {
  id?: string
  className?: string
  style?: CSSProperties
  render: RichTextBlock[] | undefined
  replacementData?: Record<string, string>
}

const PresentRichText: FC<Props> = ({ className, style, id, render, replacementData, ...props }) => {
  const { replacements } = usePlaceholderReplacements(replacementData)
  const newRender = useMemo(() => processPlaceholders(render || [], replacements || {}), [render, replacements])

  if (!hasTextPresent(render)) {
    return null
  }

  return (
    <div className={cx(styles.presentRichText, className)} id={id} style={style}>
      <RichText render={newRender} {...props} />
    </div>
  )
}

export default PresentRichText
