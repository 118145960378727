import cx from 'classnames'
import { NavGroup, NavItem, NavItems } from './types'
import React, { FC } from 'react'

import styles from './styles.module.scss'

interface NavDesktopProps {
  activeNavItem?: NavItem | NavGroup
  navItems: NavItems
}

const NavDesktop: FC<NavDesktopProps> = ({ activeNavItem, navItems }) => {
  const activeItem = activeNavItem as NavItem
  return (
    <nav className={styles.navDesktop}>
      {Object.values(navItems).map((itemOrGroup: NavItem | NavGroup) => {
        if (itemOrGroup.type === 'item') {
          const item = itemOrGroup as NavItem
          return (
            <a
              className={cx(styles.navDesktopItem, {
                [styles.navDesktopItemActive]: activeItem?.href === item.href,
                [styles.navDesktopItemGetStarted]: [
                  (navItems.GetStarted as NavItem)?.href,
                  (navItems.Dashboard as NavItem)?.href,
                ].includes(item.href),
                [styles.navDesktopItemDownloadApp]: (navItems.DownloadApp as NavItem)?.href == item.href,
              })}
              style={item.hideOn == 'logged-out' ? { display: 'none' } : undefined}
              href={item.href}
              key={item.title}
              target={item.target}
              data-hide-on={item.hideOn}
              data-display="flex"
            >
              {item.title}
            </a>
          )
        } else {
          const group = itemOrGroup as NavGroup
          return (
            <div className={styles.submenu} key={group.title}>
              {group.href ? (
                <a
                  href={group.href}
                  className={cx(styles.submenuButton, {
                    [styles.submenuButtonActive]: group.items.map((e) => e.href).includes(activeItem?.href),
                  })}
                  style={group.hideOn == 'logged-out' ? { display: 'none' } : undefined}
                  data-hide-on={group.hideOn}
                >
                  {group.title}
                </a>
              ) : (
                <div
                  role="button"
                  tabIndex={0}
                  className={cx(styles.submenuButton, {
                    [styles.submenuButtonActive]: group.items.map((e) => e.href).includes(activeItem?.href),
                  })}
                  style={group.hideOn == 'logged-out' ? { display: 'none' } : undefined}
                  data-hide-on={group.hideOn}
                >
                  {group.title}
                </div>
              )}
              <div className={styles.submenuPanel} role="dialog">
                {group.items.map((item) => (
                  <a
                    className={cx(styles.navDesktopItem, {
                      [styles.navDesktopItemActive]: activeItem?.href === item.href,
                    })}
                    style={item.hideOn == 'logged-out' ? { display: 'none' } : undefined}
                    href={item.href}
                    key={item.title}
                    target={item.target}
                    data-hide-on={item.hideOn}
                    data-display="flex"
                  >
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
          )
        }
      })}
    </nav>
  )
}

export default NavDesktop
