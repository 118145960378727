import React, { FC, PropsWithChildren, useContext } from 'react'
import { RichTextBlock, RichTextSpan, Link } from 'prismic-reactjs'

export const hasTextPresent = (textData?: RichTextBlock[]): boolean => {
  return !!textData?.find((e) => (e.text?.length || 0) > 0)
}

interface Placeholder {
  key: string
  start: number
  placeholderLength: number
}

interface Replacement extends Placeholder {
  value: string
  replacementLength: number
}

function cloneBlock(block: RichTextBlock): RichTextBlock & { text: string } {
  return {
    ...block,
    text: block.text || '',
    spans: block.spans?.map(cloneSpan),
    dimensions: block.dimensions ? { ...block.dimensions } : undefined,
    linkTo: cloneLink(block.linkTo),
  }
}

function cloneSpan(span: RichTextSpan): RichTextSpan {
  return {
    ...span,
    data: cloneLink(span.data),
  }
}

function cloneLink(link?: Link): Link | undefined {
  if (!link) return undefined
  return {
    ...link,
    tags: link.tags ? [...link.tags] : undefined,
  }
}

// Our placeholders are anything between {{ and }},
// where everything inside is the key. Because the content to
// be replaced is potentially different in size from that which is
// replacing it, we need to extract and replace them as one.
//
// Pull out any placeholders and record where they are in the original
// string
function extractPlaceholders(text: string): Array<Placeholder> {
  const placeholders: Array<Placeholder> = []
  let open = ''
  let close = ''
  let key = ''
  let start = 0

  for (let i = 0; i < text.length; i++) {
    const c = text[i]
    if (open == '') {
      if (c == '{') {
        open = '{'
        start = i
      }
    } else if (open == '{') {
      if (c == '{') {
        open = '{{'
      } else {
        open = ''
        start = 0
      }
    } else if (open == '{{') {
      if (c == '}' && close == '}') {
        placeholders.push({
          key: key.trim(),
          start: start,
          placeholderLength: i - start + 1,
        })
        start = 0
        key = ''
        close = ''
        open = ''
      } else if (c == '}') {
        close = '}'
      } else if (close == '}') {
        // Premature }
        close = ''
        key += '}' + c
      } else {
        key += c
      }
    }
  }
  return placeholders
}
// From our data map, any placeholders that have replacement text
// needs to be replaced. If it doesn't, replace it with empty string.
function replacePlaceholders(placeholder: Array<Placeholder>, data: Record<string, string>): Array<Replacement> {
  return placeholder.map((placeholder) => {
    const value = data[placeholder.key]?.toString() || ''
    return {
      ...placeholder,
      value: value,
      replacementLength: value.length,
    }
  })
}
// Now go through and update the original text with our replacements.
function updateText(text: string, replacements: Array<Replacement>): string {
  const parts: Array<string> = []
  let lastEnd = 0
  replacements.forEach((replacement) => {
    const prefix = text.substring(lastEnd, replacement.start)
    lastEnd = replacement.start + replacement.placeholderLength
    parts.push(prefix, replacement.value)
  })
  return parts.concat([text.substring(lastEnd)]).join('')
}
// Prismic uses 'spans' of text to denote where text enhancements should go, like
// bold and italics. Since our replacements probably shifted the length of the text,
// the position of the spans' starts and ends could be off and need adjusting.
function updateSpans(spans: Array<RichTextSpan>, replacements: Array<Replacement>): Array<RichTextSpan> {
  spans.forEach((span) => {
    // Adjust our span start by any replacement start that happened before our span start.
    span.start += replacements.reduce((sum, replacement) => {
      if (replacement.start >= span.start) return sum
      return sum - replacement.placeholderLength + replacement.replacementLength
    }, 0)
    // Adjust our span end by any replacement end that happened before our span end.
    span.end += replacements.reduce((sum, replacement) => {
      const replacementEnd = replacement.start + replacement.placeholderLength - 1
      if (replacementEnd >= span.end) return sum
      return sum - replacement.placeholderLength + replacement.replacementLength
    }, 0)
  })
  return spans
}

export function processPlaceholders(
  textData: Array<RichTextBlock>,
  data: Record<string, string>
): Array<RichTextBlock> {
  return textData?.map((block) => {
    if (typeof block.text === 'undefined') return block
    const newBlock = cloneBlock(block)
    const placeholders: Array<Placeholder> = extractPlaceholders(newBlock.text)
    if (placeholders.length == 0) return block

    const replacements: Array<Replacement> = replacePlaceholders(placeholders, data || {})

    newBlock.text = updateText(newBlock.text, replacements)
    if (typeof newBlock.spans !== 'undefined') {
      newBlock.spans = updateSpans(newBlock.spans, replacements)
    }
    return newBlock
  })
}

export interface PlaceholderReplacementContextType {
  replacements?: Record<string, string>
}
const PlaceholderReplacementContext = React.createContext<PlaceholderReplacementContextType>({
  replacements: undefined,
})

export function usePlaceholderReplacements(
  additionalReplacements?: Record<string, string>
): PlaceholderReplacementContextType {
  const { replacements } = useContext(PlaceholderReplacementContext)
  return { replacements: { ...replacements, ...additionalReplacements } }
}

interface Props {
  replacements?: Record<string, string>
}

export const PlaceholderReplacements: FC<PropsWithChildren<Props>> = ({ replacements, children }) => {
  return (
    <PlaceholderReplacementContext.Provider value={{ replacements: replacements }}>
      {children}
    </PlaceholderReplacementContext.Provider>
  )
}
